import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { RainbowKitProvider, darkTheme, Theme, getDefaultWallets, lightTheme } from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createClient,
  WagmiConfig,
  mainnet
} from "wagmi";

import "@rainbow-me/rainbowkit/styles.css";
import { infuraProvider } from 'wagmi/providers/infura';
import { polygonMumbai ,sepolia} from "@wagmi/chains"

const { chains, provider } = configureChains(
  [mainnet],
  [
    infuraProvider({
      apiKey: "014624cb65e2436b867f49ef0a3c84e3",
    }),

  ]
);


const { connectors } = getDefaultWallets({
  appName: 'movie',
  projectId: '846b33206741a4b672e61a1a4d1a3110',
  chains
});
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
         coolMode
         theme={lightTheme({
           accentColor: '#000000',
           accentColorForeground: 'white',
           borderRadius: 'large',
           fontStack: 'system',
           overlayBlur: 'large',
         })} chains={chains}
      >
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
