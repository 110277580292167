import { useState, useEffect } from "react";
import { createContext, useContext } from "react";
import { ethers } from "ethers";
import { shirtNftAdd } from "../contracts/config.js";
import shirtNftAbi from '../contracts/shirtNftAbi.json'
import { useSigner } from "wagmi";

export const ContractContext= createContext();

export const ContractAPI = ({children}) =>{
    const [shirtColors, setShirtColors] = useState()
    const [pairOfShirts, setPairOfShirts] = useState()
    const [pricePerShirt, setPricePerShirt] = useState()
    const [noOfShirt, setNoOfShirt] = useState();
    const [isLoading, setIsLoading] = useState(false)
    
    
    const [selectedValueSize, setSelectedValueSize] = useState()
    const [colorVal, setColorVal] = useState('white')
    const [Signer, setSigner] = useState()
    const {data: signer} = useSigner()
    
    
    console.log('color val ----> ',colorVal);
    
    
    
    
    
    useEffect(()=> {
        // get Shirt Colors
        
        // const getColorFunction=async( contract)=>{
        //     const res = await contract?.getShirtColours();
        //     setShirtColors(res)
        // }
        
        // get Pair of Shirts
        // const pairOfShirts=async( contract)=>{
        //     const res = await contract?.getPairsOfShirt(colorVal);
        //     setPairOfShirts(res)
        // }

        // get Price of Shirts
        const priceOfShirts=async( contract)=>{
            const res = await contract?.pricePerNFT(colorVal);
            setPricePerShirt(res)
        }

        // No of pair of shirts
        const noOfShirts=async( contract)=>{
            if(colorVal && selectedValueSize){
                const res = await contract?.nofOfShirtsPerSize(colorVal, selectedValueSize);
                setNoOfShirt(res)
            }
        }

        

    //     let signer
    //     try {
    //         const provider = new ethers.providers.Web3Provider(window.ethereum)
    // // await provider.send("eth_requestAccounts", []);
    //         signer = provider?.getSigner();
    //         setSigner(signer);
            
    //     } catch (error) {
    //         console.log("error while fetching signer :",error);
    //     }
       
        if(signer){

            const nftContract = new ethers.Contract(shirtNftAdd, shirtNftAbi, signer)
            console.log("contract instance :",nftContract)
            if(nftContract) {
                // getColorFunction(nftContract);
                // pairOfShirts(nftContract);
                priceOfShirts(nftContract);
                noOfShirts(nftContract)

            }
            console.log('no of shirts===>', signer);
        }
       
    }, [colorVal, signer, selectedValueSize])

    return(
        <ContractContext.Provider value={{isLoading, setIsLoading, noOfShirt, setSelectedValueSize, Signer, colorVal, setColorVal, shirtColors, pairOfShirts, pricePerShirt, setPairOfShirts, setShirtColors, setPricePerShirt }}>
            {children}
        </ContractContext.Provider>
    )
}

export const useContractContext = () => useContext(ContractContext);
