import Footer from "../components/footer";
import Header from "../components/header";
import { useEffect } from "react";


export default function TermsOfUsePage(){
    window.scrollTo(0, 0); 
    useEffect(()=>{
      });
    
    return(
        <div className="accessibility-statement-page">
            <Header />
            <div className="access-page-det pt-20 md:pt-0 max-w-[1060px] px-4 md:px-16 mx-auto">
                <h1 data-aos="fade-up" data-aos-duration="700" className="pt-4 mb-4 md:mb-16 md:pt-40 text-3xl lg:text-4xl font-bold text-center">Terms of Use for Hume Studios</h1>
                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Introduction:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">Welcome to Hume Studios! These Terms of Use ("Terms") along with our Privacy Policy govern your access to and use of Hume Studios' website, services, and applications (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Important Arbitration Notice:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">By agreeing to these Terms, you are agreeing to resolve any dispute through binding arbitration, waiving the right to a trial by jury or to participate in a class action, subject to applicable law. Please read Section 14 "Dispute Resolution" carefully.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Acceptance of Terms:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">By using the Service, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you must not use the Service.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Privacy Policy:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">Our Privacy Policy details how we handle your personal data and protect your privacy when you use our Service. By using the Service, you agree to such processing and you warrant that all data provided by you is accurate.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Modifications to Terms or Service:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">We reserve the right to modify these Terms at any time. We will post the revised Terms on the Service and update the "Last Updated" date to reflect the date of the changes. By continuing to use the Service after the changes become effective, you agree to the revised Terms.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Eligibility:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and warrant that you are of legal age to form a binding contract with Hume Studios.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">User Feedback:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">While we appreciate your feedback, please note that any comments, ideas, or suggestions sent to Hume Studios are provided on a non-confidential basis and we are under no obligation to keep such information secret.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Intellectual Property Rights:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">The Service and its original content, features, and functionality are and will remain the exclusive property of Hume Studios and its licensors.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Prohibited Uses:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">You agree not to misuse the Service or help anyone else do so. You agree not to, nor try to, circumvent any security feature or to interfere with the proper working of the Service, or impair, overburden, or harm the Service.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Links to Other Websites:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">Our Service may contain links to third-party websites or services that are not owned or controlled by Hume Studios. We have no control over, and assume no responsibility for, their content, privacy policies, or practices.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Termination:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">We may terminate or suspend your access to our Service immediately, without prior notice or liability, for any reason, including without limitation if you breach the Terms.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Disclaimers:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">The Service is provided on an "as is" and "as available" basis. Hume Studios expressly disclaims any warranties and conditions of any kind, whether express or implied.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Indemnification:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">You agree to indemnify, defend, and hold harmless Hume Studios, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or relating to your breach of these Terms or your use of the Service.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Limitation of Liability:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">In no event will Hume Studios be liable for any indirect, incidental, special, consequential or punitive damages resulting from or related to your use of the Service.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Governing Law:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">These Terms shall be governed by the laws of the jurisdiction in which Hume Studios operates, without regard to its conflict of law provisions.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Dispute Resolution:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">These Terms constitute the entire agreement between you and Hume Studios regarding the Service and supersede and replace any prior agreements we might have had between us regarding the Service.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">General:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">Any disputes will be resolved through final and binding arbitration, except as set forth under Exceptions to Agreement to Arbitrate in this Section.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Contact Us:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">For any questions about these Terms, please contact us at <b> info@humestudios.xyz</b></p>
                </div>
            </div>
            <Footer />
        </div>
    )
}