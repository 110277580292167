import { Link, useLocation } from "react-router-dom";

import { useEffect, useState } from "react";
import LoginModal from "../modals/loginModal";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ConnectBtn } from "./connectBtn";
import { RxCross2 } from "react-icons/rx";
import { FaChevronRight } from "react-icons/fa6";


export default function Header() {
  const [isBarOpen, setBarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  // console.log(location);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 786) {
        setBarOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleBar = () => {
    setBarOpen(!isBarOpen);
  };

  return (
    <nav
      class={
        location.pathname === "/"
          ? `fixed z-20 w-full  ${isBarOpen ? 'bg-white' : 'bg-white/0'} border-gray-200`
          :" fixed z-20 w-full bg-white border-gray-200"
      }
    >
      <div class="flex flex-wrap items-center bg-black/0 justify-between mx-auto p-4 gap-x-2 md:gap-x-12 lg:py-6 lg:px-16">
        <Link to={"/"} class="flex items-center space-x-3 rtl:space-x-reverse">
          {/* <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" /> */}
          <span class="self-center text-xl md:text-3xl font-black whitespace-nowrap text-[#808080] uppercase">
            Hume Studios
          </span>
        </Link>

        <div class="w-5 overflow-hidden flex grow md:hidden justify-end md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <div
              class={' '}
            >
              <ConnectBtn isBarOpen={isBarOpen} location={location} />
            </div>
        </div>

        <button
          onClick={toggleBar}
          data-collapse-toggle="navbar-default"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none "
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          {
            isBarOpen ?
            <RxCross2 size={25}/>
            :
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          }
        </button>
        <div
          class={
            isBarOpen
              ? "grow flex justify-between flex-col w-full h-screen md:h-auto md:w-auto bg-white  border-gray-200 py-4"
              : "hidden grow md:flex justify-between items-center flex-row w-full md:w-auto"
          }
          id="navbar-default"
        >
          <ul class="font-black flex flex-col md:p-0  rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:items-center">
            <li>
              <Link
                to={"/mint"}
                class={`${
                  location.pathname === "/"
                    ? `text-left flex items-center justify-between  py-2 uppercase px-2 ${
                        isBarOpen ? "text-black " : "text-white"
                      } hover:bg-gray-100 md:hover:bg-gray-400/45 `
                    : "text-left flex items-center justify-between uppercase py-2 px-2 text-black  hover:bg-gray-100 md:hover:bg-gray-400/45"
                }`}
              >
                Mint
                {
                  isBarOpen?
                  <FaChevronRight /> :
                  <></>
                }

              </Link>
            </li>
            <li>
              <Link
                to={"/mission"}
                class={
                  location.pathname === "/"
                    ? `text-left flex items-center justify-between uppercase py-2 px-2 ${
                        isBarOpen ? "text-black " : "text-white"
                      } font-black hover:bg-gray-100 md:hover:bg-gray-400/45 `
                    : "text-left flex items-center justify-between uppercase py-2 px-2 text-black font-black hover:bg-gray-100 md:hover:bg-gray-400/45"
                }
              >
                Mission
                {
                  isBarOpen?
                  <FaChevronRight /> :
                  <></>
                }

              </Link>
            </li>
            <li>
              <Link
                to={"https://docs.humestudios.xyz/"}
                class={
                  location.pathname === "/"
                    ? `text-left flex items-center justify-between uppercase py-2 px-2 ${
                        isBarOpen ? "text-black " : "text-white"
                      } font-black hover:bg-gray-100 md:hover:bg-gray-400/45 `
                    : "text-left flex items-center justify-between uppercase py-2 px-2 text-black font-black hover:bg-gray-100 md:hover:bg-gray-400/45"
                }
              >
                Docs
                {
                  isBarOpen?
                  <FaChevronRight /> :
                  <></>
                }
              </Link>
            </li>
          </ul>
          <div class="hidden md:flex md:order-2 space-x-3 md:space-x-0 justify-end rtl:space-x-reverse">
            <div
              
              class={'w-full px-4'}
            >
              <ConnectBtn isBarOpen={isBarOpen} location={location} />
            </div>
          </div>
        </div>
      </div>

      <LoginModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </nav>
  );
}
