import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import humestImg from "../images/humest1.png";
import modeImg2 from "../images/2 .png";
import modeImg3 from "../images/3.png";
import modeImg4 from "../images/4.png";
import modeImg5 from "../images/5.png";
import modeImg6 from "../images/g.png";
import "animate.css";
import { Link, NavLink } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { motion } from "framer-motion";

// import './styles.css';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

export default function LandingSwiper() {
  return (
    <>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={false}
        keyboard={false}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper h-[100vh] overflow-hidden"
      >
        <SwiperSlide>
          <div className="img w-full h-full">
            <img
              className="h-full w-full object-cover"
              src={
                "https://assets-global.website-files.com/659ff77944eb912fe4c56ad6/65e38a62d486d28f6801cbfe_4.jpeg"
              }
              alt="model image 0"
            />
            <div className="w-full absolute top-[50%] translate-y-[-50%] z-20 flex flex-col items-center md:items-center md:px-4 justify-center">
              <div className="sec w-full">
                <motion.h1
                  initial={{ scale: 0.4, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1.0, ease: [0.42, 0, 0.58, 1] }}
                  className="w-full h-auto text-center drop-shadow-2xl text-[12vw] md:text-[14vw] leading-[60%] lg:text-[16vw] font-black z-20 text-white uppercase"
                >
                  Hume Studios
                </motion.h1>

                <motion.p
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1.0, ease: "easeInOut" }}
                  className="text-2xl md:text-3xl lg:text-4xl drop-shadow-xl text-center lg:text-right lg:mr-5 font-black mt-5 md:mt-10 lg:mt-20 z-20 text-white"
                >
                  Human Focused Design
                </motion.p>

              </div>
              <NavLink to={'/mint'}>
                <button
                  type="button"
                  class="z-50 mx-auto px-6 mt-4 py-3.5 text-2xl font-semibold text-white bg-black hover:bg-neutral-800 rounded-lg text-center"
                >
                  Mint Now
                </button>
              </NavLink>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="img w-full h-full">
            <img
              className="h-full w-full object-cover"
              src={
                modeImg6
              }
              alt="model image 1"
            />
             <div className="w-full absolute top-[50%] translate-y-[-50%] z-20 flex flex-col items-center md:items-center md:px-4 justify-center">
              <div className="sec w-full">
                <motion.h1
                  initial={{ scale: 0.4, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1.0, ease: [0.42, 0, 0.58, 1] }}
                  className="w-full h-auto drop-shadow-xl text-center text-[12vw] md:text-[14vw] leading-[60%] lg:text-[16vw] font-black z-20 text-white uppercase"
                >
                  Hume Studios
                </motion.h1>

                <motion.p
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1.0, ease: "easeInOut" }}
                  className="text-2xl drop-shadow-xl md:text-3xl lg:text-4xl text-center lg:text-right font-black mt-5 md:mt-10 lg:mt-20 z-20 text-white"
                >
                  Human Focused Design
                </motion.p>
              </div>
              <NavLink to={'/mint'}>
                <button
                  type="button"
                  class="z-50 mx-auto px-6 mt-4 py-3.5 text-2xl font-semibold text-white bg-black hover:bg-neutral-800 rounded-lg text-center"
                >
                  Mint Now
                </button>
              </NavLink>
            </div> 
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="img w-full h-full">
            <img
              className="h-full w-full object-cover"
              src={
                modeImg5
              }
              alt="model image"
            />
            <div className="w-full absolute top-[50%] translate-y-[-50%] z-20 flex flex-col items-center md:items-center md:px-4 justify-center">
              <div className="sec w-full">
                <motion.h1
                  initial={{ scale: 0.4, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 1.0, ease: [0.42, 0, 0.58, 1] }}
                  className="w-full h-auto drop-shadow-xl text-center text-[12vw] md:text-[14vw] leading-[60%] lg:text-[16vw] font-black z-20 text-white uppercase"
                >
                  Hume Studios
                </motion.h1>

                <motion.p
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 1.0, ease: "easeInOut" }}
                  className="text-2xl drop-shadow-xl md:text-3xl lg:text-4xl text-center lg:text-right font-black mt-5 md:mt-10 lg:mt-20 z-20 text-white"
                >
                  Human Focused Design
                </motion.p>
              </div>
              <NavLink to={'/mint'}>
                <button
                  type="button"
                  class="z-50 mx-auto px-6 mt-4 py-3.5 text-2xl font-semibold text-white bg-black hover:bg-neutral-800 rounded-lg text-center"
                >
                  Mint Now
                </button>
              </NavLink>
            </div> 
          </div>
        </SwiperSlide>

        
      </Swiper>
    </>
  );
}
