import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import humestImg from "../images/humest1.png";
import modeImg2 from "../images/mint1.jpg";
import modeImg3 from "../images/3.png";
import modeImg4 from "../images/mint2.jpg";
import modeImg5 from "../images/5.png";
import modeImg6 from "../images/g.png";
import "animate.css";
import { Link, NavLink } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { motion } from "framer-motion";

// import './styles.css';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay,  } from "swiper/modules";

export default function MintSwiper() {
  return (
    <>
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={false}
        keyboard={false}
        loop={true}
        autoplay={{
            delay: 3000,
            disableOnInteraction: false,
        }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        modules={[Navigation, Pagination, Mousewheel, Autoplay]}
        className="mySwiper h-fit min-[280px]:w-[250px] min-[380px]:w-[350px] min-[440px]:w-[400px] md:w-[600px]"
      >
        <SwiperSlide>
          <div className="img w-[180px] min-[280px]:w-[250px] min-[280px]:h-[250px] min-[380px]:w-[350px] min-[380px]:h-[350px] min-[440px]:w-[500px] min-[440px]:h-[500px] md:w-[600px] md:h-[600px]">
            <img
              className="h-full w-full object-cover"
              src={
                modeImg4
              }
              alt="model image 0"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="img min-[280px]:w-[250px] min-[280px]:h-[250px] min-[380px]:w-[350px] min-[380px]:h-[350px] min-[440px]:w-[500px] min-[440px]:h-[500px] md:w-[600px] md:h-[600px]">
            <img
              className="h-full w-full object-cover"
              src={
                modeImg2
              }
              alt="model image 1"
            />
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="img w-[600px] h-[600px]">
            <img
              className="h-full w-full object-cover"
              src={
                modeImg2
              }
              alt="model image 1"
            />
          </div>
        </SwiperSlide> */}


        
      </Swiper>
    </>
  );
}
