import Header from "../components/header";
import { Link } from "react-router-dom";
import humestImg from '../images/humest1.png'
import humestImg2 from '../images/humest2.png'
import humestImg2Big from '../images/humest2big.png'
import humestImg2Bigcrp from '../images/humest2bigcrop.jpg'
import LoginModal from "../modals/loginModal";
import LandingSwiper from "../components/landingSwiper";


export default function LandingPage(){

    return(
        
        <div className="langing-page">
            <Header />
            
           <LandingSwiper />
        </div>
    )
}