import { useState, useEffect } from "react";
import { createContext, useContext } from "react";

export const WriteContext= createContext();

export const WriteAPI = ({children}) =>{
    const [color, setColor] = useState()

    
    

    return(
        <WriteContext.Provider value={{ color, setColor }}>
            {children}
        </WriteContext.Provider>
    )
}

export const useWriteContext = () => useContext(WriteContext);
