import FaqCard from "../components/faqCard";
import Footer from "../components/footer";
import Header from "../components/header";
import { useEffect } from "react";


export default function AccessStatementPage(){
    window.scrollTo(0, 0); 
    useEffect(()=>{
      });
    
    return(
        <div className="accessibility-statement-page">
            <Header />
            <div className="access-page-det pt-20 md:pt-0 max-w-[1060px] px-4 md:px-16 mx-auto">
                <h1 data-aos="fade-up" data-aos-duration="700" className="pt-4 mb-4 md:mb-16 md:pt-40 text-3xl lg:text-4xl font-bold text-center">ACCESSIBILITY STATEMENT</h1>
                <h3 data-aos="fade-up" data-aos-duration="200"  className="mt-2 font-semibold text-base">Our Commitment to Accessibility</h3>
                <p  data-aos="fade-up" data-aos-duration="300" className="mt-3 text-base">Hume Studios  is committed to making our website's content accessible and user friendly to everyone. If you are having difficulty viewing or navigating the content on this website, or notice any content, feature, or functionality that you believe is not fully accessible to people with disabilities, please email our team at info@humestudios.xyz with “Disabled Access” in the subject line and provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement.</p>
                <p  data-aos="fade-up" data-aos-duration="400" className="mt-3 text-base">We take your feedback seriously and will consider it as we evaluate ways to accommodate all of our customers and our overall accessibility policies.  Additionally, while we do not control such vendors, we strongly encourage vendors of third-party digital content to provide content that is accessible and user friendly.</p>
            </div>
            <Footer />
        </div>
    )
}