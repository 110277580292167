import logo from './logo.svg';
import './App.css';
import MintProductsPage from './pages/mint';
import { HashRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landingpage';
import MissionPage from './pages/mission';
import Faqs from './pages/faqs';
import { ContractAPI } from './context/nftShirtContext';
import { WriteAPI } from './context/writeContext';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useEffect } from 'react';
import AccessStatementPage from './pages/accessStatment';
import TermsOfUsePage from './pages/termofService';
import PrivacyPolicyPage from './pages/privacyPolicy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    AOS.init();
    document.addEventListener("DOMContentLoaded", function() {
      setTimeout(function() { AOS.refresh(); }, 200);
  });
  }, []);
  return (
   <div>
    <WriteAPI>
      <ToastContainer />
      <ContractAPI>
          <HashRouter className="App controlling-flow">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/mint" element={<MintProductsPage />} />
              <Route path="/mission" element={<MissionPage />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/accessibility-statement" element={<AccessStatementPage />} />
              <Route path="/terms-of-use" element={<TermsOfUsePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Routes>
          </HashRouter>
      </ContractAPI>
    </WriteAPI>
   </div>
  );
}

export default App;
