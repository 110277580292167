import { useWriteContext } from "../context/writeContext";
import CartModal from "../modals/cartModal"
import { useState } from "react"
import { useContractContext } from "../context/nftShirtContext";
import { useSigner } from "wagmi";
import tShirtVid from '../images/t-shirt-anim.mp4'


export default function Card(props){
   
    const [isOpen, setIsOpen] = useState(false);
    const {setColor} = useWriteContext()
    const {setColorVal} = useContractContext()


    const openCart = ()=>{
        setColor(props.color)
        setColorVal(props.color)
        setIsOpen(true)

    }

    return(
        <div data-aos="fade-up" data-aos-duration="800" class="h-full  border-gray-400 ">
            <a >
                <h5 class="mb-3 text-base uppercase font-bold tracking-tight text-black ">V1 Hume Studios Tee</h5>
            </a>
        
            <video className="w-full h-[480px] lg:h-[550px] object-cover md:object-cover"  type="video/mp4" autoPlay muted loop>
                <source src={tShirtVid} type="video/mp4" />
            </video>
                {/* <img class="h-[480px] lg:h-[550px] w-full object-cover bg-none" src={props.image} alt="" /> */}
           
            <div class="pt-4 w-full flex justify-center mx-auto">
                
                {/* <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p> */}
                <a onClick={()=>openCart()} class="inline-flex items-center justify-center w-full  px-7 py-3 text-lg font-medium text-center text-[white] bg-[#7e7e7e] hover:bg-neutral-800 focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-pointer">
                    Mint
                    {/* <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                    </svg> */}
                </a>
            </div>
            <CartModal isOpen={isOpen} setIsOpen={setIsOpen} />

        </div>
    )
}