import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useWriteContext } from "../context/writeContext";
import { useContractContext } from "../context/nftShirtContext";
import { ethers, BigNumber } from "ethers";
import { shirtNftAdd } from "../contracts/config.js";
import shirtNftAbi from "../contracts/shirtNftAbi.json";
import { useSigner } from "wagmi";
import { ConnectBtn } from "../components/connectBtn.jsx";
import { ConnectBtnYellow } from "../components/connectBtnYellow.jsx";
import tshirtBlack from "../images/t_shirt_black.png";
import tshirtWhite from "../images/t_shirt_white.png";
import { toast } from "react-toastify";

export default function CartModal({ isOpen, setIsOpen }) {
  const { data: signer } = useSigner();
  const { color, setColor } = useWriteContext();
  const {
    pairOfShirts,
    isLoading,
    setIsLoading,
    Signer,
    pricePerShirt,
    setSelectedValueSize,
    noOfShirt,
    colorVal,
    setColorVal,
  } = useContractContext();
  const [selectedValue, setSelectedValue] = useState("");

  // const [payableAmount, setPayableAmount] = useState([])
  const [quantity, setQuantity] = useState(1);

  const sizesT = ["small", "medium", "large", "extraLarge"];
  const sizesTVisb = ["Small", "Medium", "Large", "Extra Large"];
  const selColors = ["Black", "White"];

  const priceINString = pricePerShirt?.toString();
  let PriceInEth;
  if (priceINString) PriceInEth = ethers.utils.formatEther(priceINString);

  const total_Price = pricePerShirt?.mul(BigNumber.from(quantity));
  let totalPriceEth;
  if (total_Price) totalPriceEth = ethers.utils.formatEther(total_Price);

  console.log("Price per shirt value===>", isLoading);

  const decreaseQuantity = () => {
    if (quantity <= 1 || quantity == 1) {
      return;
    } else {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    if (quantity >= 0) {
      setQuantity(quantity + 1);
    }
  };

  const handleformSubmit = async (e) => {
    // setIsLoading(true);
    e.preventDefault();
    console.log(
      "response from buy shirt functions:",
      color,
      selectedValue,
      quantity,
      total_Price.toString()
    );

    const valueInWei = pricePerShirt * quantity;
    const valueInEth = ethers.utils.parseEther(valueInWei.toString());
    console.log("value in wei -===>, ", pricePerShirt);

    const buyShirtsFunc = async (contact) => {
      // console.log("contact in read", contact);
      contact
        ?.buyShirts([color], [selectedValue], [quantity], {
          value: total_Price,
        })
        .then((res) => {
          res.wait().then((res) => {
            console.log("response from buy shirt functions:", res);
            toast("NFT minted sucessfully");
            setIsLoading(false);
            closeModal();
          });
        })
        .catch((err) => {
          console.log("error from buy shirt functions:", err);
          setIsLoading(false);
          toast.error(err?.reason?.toString());
        });
    };

    const nftContract = new ethers.Contract(shirtNftAdd, shirtNftAbi, signer);
    console.log("contract instance :", nftContract);
    if (nftContract) {
      if (selectedValue === "") {
        toast.error("please choose size");
      } else {
        buyShirtsFunc(nftContract);
      }
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full z-50 max-w-md transform overflow-hidden rounded-sm bg-white border border-neutral-300 p-6 text-left align-middle transition-all">
                  <div
                    id="authentication-modal"
                    tabindex="-1"
                    aria-hidden="true"
                    class="z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                  >
                    <div class="relative w-full max-w-md max-h-full">
                      {/* <!-- Modal content --> */}
                      <div class="relative bg-white rounded-lg dark:bg-gray-700">
                        {/* <!-- Modal header --> */}
                        <div class="flex items-center justify-between  rounded-t dark:border-gray-600">
                          <h3 class="grow text-2xl text-center font-black text-gray-900 dark:text-white">
                            {color === "white"
                              ? "V1 Hume Studios Tee"
                              : "V1 Hume Studios Tee"}
                          </h3>
                          <button
                            onClick={closeModal}
                            type="button"
                            class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                          >
                            <svg
                              class="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span class="sr-only">Close modal</span>
                          </button>
                        </div>
                        {/* <!-- Modal body --> */}
                        <div class="">
                          <img
                            className="mt-4 h-[250px] mx-auto"
                            src={color === "white" ? tshirtWhite : tshirtBlack}
                            alt="shirt imge"
                          />
                          <form class="space-y-4" onSubmit={handleformSubmit}>
                            <div className="mt-8">
                              <div className="choose-color flex flex-row justify-between items-center pb-3 mt-3 border-b border-neutral-300">
                                <label
                                  for="password"
                                  class="  text-xl w-40 font-semibold text-gray-900 flex flex-row items-end gap-x-2"
                                >
                                  Choose Color:
                                  {/* <small className="text-sm flex gap-x-1">no of shirts:{noOfShirt == undefined ? <p>nan</p> : noOfShirt.toString()}</small> */}
                                </label>

                                <div className="whole-checkbox">
                                  <select
                                    id="sizes"
                                    onChange={(e) => {
                                      setColor(e.target.value);
                                      setColorVal(e.target.value);
                                    }}
                                    value={color}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base font-semibold rounded-lg block w-full p-2.5 "
                                  >
                                    {/* <option selected value={''}>Choose Color</option> */}
                                    {/* {selColors?.map((pair, index) =>(
                                    
                                    
                                  ))} */}
                                    {selColors?.map((pair, index) => {
                                      // console.log("conditon", pair, color ,  pair === color);
                                      return (
                                        <option
                                          key={index}
                                          value={pair.toLowerCase()}
                                        >
                                          {pair}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className="Price flex flex-row justify-between items-center mt-3 pb-3 border-b border-neutral-300">
                                <label
                                  for="Price"
                                  class=" block text-xl font-semibold text-gray-900 dark:text-white"
                                >
                                  Price:
                                </label>

                                <div className="whole-checkbox">
                                  <p className="text-base font-semibold">
                                    {PriceInEth} ETH
                                  </p>
                                </div>
                              </div>

                              <div className="size flex flex-row justify-between items-center pb-3 mt-3 border-b border-neutral-300">
                                <label
                                  for="password"
                                  class="  text-xl w-40 font-semibold text-gray-900 flex flex-row items-end gap-x-2"
                                >
                                  Size:
                                  {/* <small className="text-sm flex gap-x-1">no of shirts:{noOfShirt == undefined ? <p>nan</p> : noOfShirt.toString()}</small> */}
                                </label>

                                <div className="whole-checkbox">
                                  <select
                                    id="sizes"
                                    onChange={(e) => {
                                      setSelectedValue(e.target.value);
                                      setSelectedValueSize(e.target.value);
                                    }}
                                    value={selectedValue}
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-base font-semibold rounded-lg block w-full p-2.5 "
                                  >
                                    <option selected value={""}>
                                      Choose Size
                                    </option>
                                    {sizesT?.map((pair, index) => (
                                      <option key={index} value={pair}>
                                        {capitalizeFirstLetter(sizesTVisb[index])}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div class="mt-3 w-full  flex flex-row justify-between items-center pb-3 border-b border-neutral-300">
                                <label
                                  for="quantity-input"
                                  class="block text-xl font-semibold text-gray-900"
                                >
                                  Quantity:
                                </label>
                                <div class="relative flex items-center max-w-[8rem]">
                                  <button
                                    type="button"
                                    onClick={decreaseQuantity}
                                    id="decrement-button"
                                    data-input-counter-decrement="quantity-input"
                                    class="bg-gray-100  hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
                                  >
                                    <svg
                                      class="w-3 h-3 text-gray-900 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 18 2"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 1h16"
                                      />
                                    </svg>
                                  </button>
                                  <input
                                    type="text"
                                    id="quantity-input"
                                    value={quantity}
                                    data-input-counter
                                    aria-describedby="helper-text-explanation"
                                    class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5"
                                    placeholder="0"
                                    required
                                  />
                                  <button
                                    type="button"
                                    onClick={increaseQuantity}
                                    id="increment-button"
                                    data-input-counter-increment="quantity-input"
                                    class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100"
                                  >
                                    <svg
                                      class="w-3 h-3 text-gray-900 dark:text-white"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 18 18"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 1v16M1 9h16"
                                      />
                                    </svg>
                                  </button>
                                </div>
                                {/* <p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a 5 digit number from 0 to 9.</p> */}
                              </div>

                              <div class="mt-3 w-full  flex flex-row justify-between items-center pb-3 border-b border-neutral-300">
                                <label
                                  for="quantity-input"
                                  class="block text-xl font-semibold text-gray-900"
                                >
                                  Total:
                                </label>
                                <div class="relative  text-2xl font-semibold flex items-center ">
                                  {totalPriceEth} ETH
                                </div>
                              </div>
                            </div>

                            {signer ? (
                              <button
                                onClick={() => setIsLoading(true)}
                                type="submit"
                                class="w-full disabled:opacity-75 text-white bg-neutral-950 hover:bg-neutral-800 font-semibold rounded text-xl px-5 py-4 text-center"
                                // disabled={isLoading ? true : false}
                              >
                                {isLoading ? "Minting ..." : "Buy Now"}
                              </button>
                            ) : (
                              <div className="flex justify-center w-full">
                                <ConnectBtnYellow />
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
