import humest1 from '../images/humest1.png'


export default function FaqCard(props){
    return(
        <div 
        data-aos='fade-up'
        data-aos-duration="500"
        >
            
            <div class="left w-full h-full p-12 bg-white border border-gray-200 transition duration-500  hover:ease-in-out hover:shadow-2xl hover:scale-105">
                <div className="q-num w-12 h-12 rounded-full bg-gray-100 flex justify-center items-center mb-4">
                    <p className="text-xl font-semibold">{props.num}</p>
                </div>
                <a href="#">
                    <h5 class="mt-6 text-2xl font-semibold tracking-tight text-gray-900 ">{props.title}</h5>
                </a>
                <p class="mt-6 font-normal text-[#111111] ">{props.details}</p>
                    
            </div>
            
        </div>
    )
}