import FaqCard from "../components/faqCard";
import Footer from "../components/footer";
import Header from "../components/header";
import humest1 from '../images/humest1.png'



export default function Faqs(){
    return(
        
        <div className="faqs-page">
            <Header />
            <div className="whole-faq pt-20 md:pt-0 max-w-[1060px] px-4 md:px-16 mx-auto">
                <h1 data-aos="fade-up" data-aos-duration="800" className="pt-4 mb-4 md:mb-16 md:pt-40 text-3xl lg:text-5xl font-bold text-center">Questions</h1>
                <div className="faq-sections grid grid-cols-1 gap-y-12 lg:gap-y-16">
                    <div className="faq-sec w-full grid grid-cols-1 gap-y-12 lg:gap-y-16">
                        <div className="top grid grid-cols-1 md:grid-cols-2 gap-y-12 lg:gap-y-16 gap-x-12 lg:gap-x-16">
                            <FaqCard num={1} isImg={false} title={'Do You have own production?'} details={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.'} />
                            <FaqCard num={2} isImg={false} title={'What is the largest size you have?'} details={'Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet'} />
                        </div>
                        
                        <div 
                        data-aos='fade-up'
                        data-aos-duration="500"
                        >
                            <div
                            class="w-full h-full flex flex-col items-center bg-white border border-gray-200 md:flex-row  transition duration-300  hover:ease-in-out hover:shadow-2xl  hover:scale-105"
                            >
                                <div class="flex flex-col justify-between p-12 md:w-1/2 leading-normal">
                                    <div className="q-num w-12 h-12 rounded-full bg-gray-100 flex justify-center items-center mb-4">
                                        <p className="text-xl font-semibold">3</p>
                                    </div>
                                    <h5 class="mt-6 text-2xl font-bold tracking-tight text-gray-900">How to take care of clothes?</h5>
                                    <p class="mt-6 font-normal text-gray-700">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</p>
                                </div>
                                <img class="object-cover w-full h-full md:w-1/2" src={'https://assets-global.website-files.com/659ff77944eb912fe4c56ad6/659ff77944eb912fe4c56c31_Frame%25203-p-500.jpeg'} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="faq-sec w-full grid grid-cols-1 gap-y-12 lg:gap-y-16">
                        <div className="top grid grid-cols-1 md:grid-cols-2 gap-y-12 lg:gap-y-16 gap-x-12 lg:gap-x-16">
                            <FaqCard num={1} isImg={false} title={'Do You have own production?'} details={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.'} />
                            <FaqCard num={2} isImg={false} title={'How to take care of clothes?'} details={'Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. '} />
                        </div>
                        
                        <div
                        data-aos='fade-up'
                        data-aos-duration="500"
                        >
                            <div
                            class="w-full h-full flex flex-col items-center bg-white border border-gray-200 md:flex-row  transition duration-300  hover:ease-in-out hover:shadow-2xl  hover:scale-105"
                            >
                                <img class="object-cover w-full h-full md:w-1/2" src={'https://assets-global.website-files.com/659ff77944eb912fe4c56ad6/659ff77944eb912fe4c56c32_Frame%25206-p-500.jpeg'} alt="" />
                                <div class="flex flex-col justify-between p-12 md:w-1/2 leading-normal">
                                    <div className="q-num w-12 h-12 rounded-full bg-gray-100 flex justify-center items-center mb-4">
                                        <p className="text-xl font-semibold">6</p>
                                    </div>
                                    <h5 class="mt-6 text-2xl font-bold tracking-tight text-gray-900">'What is the largest size you have?'</h5>
                                    <p class="mt-6 font-normal text-gray-700">Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}