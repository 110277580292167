import Footer from "../components/footer";
import Header from "../components/header";
import { useEffect } from "react";


export default function PrivacyPolicyPage(){
    window.scrollTo(0, 0); 
    useEffect(()=>{
      });
    
    return(
        <div className="accessibility-statement-page">
            <Header />
            <div className="access-page-det pt-20 md:pt-0 max-w-[1060px] px-4 md:px-16 mx-auto">
                <h1 data-aos="fade-up" data-aos-duration="700" className="pt-4 mb-4 md:mb-16 md:pt-40 text-3xl lg:text-4xl font-bold text-center">Privacy Policy for Hume Studios</h1>
                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Introduction:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">Welcome to Hume Studios! Your privacy is crucial to us. This Privacy Policy outlines how Hume Studios ("we", "us", or "our") collects, uses, and shares your personal information when you use our services, websites, mobile applications, and other online platforms (collectively, the “Services”). It also describes your choices and rights regarding the use, access, and correction or deletion of your personal information.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Scope:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">By agreeing to these Terms, you are agreeing to resolve any dispute through binding arbitration, waiving the right to a trial by jury or to participate in a class action, subject to applicable law. Please read Section 14 "Dispute Resolution" carefully.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Personal Information We Collect:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base"><span className="font-medium">Information You Provide:</span> This may include your name, email address, postal address, payment information, and any other information you choose to provide. </p>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base"><span className="font-medium">Information We Collect Automatically:</span>  When you use our Services, we may collect information sent to us by your computer, mobile phone, or other access device. This information includes your IP address, device information, and browser information.</p>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base"><span className="font-medium">Information from Third Parties:</span> We may receive information about you from third parties, including our business partners, and publicly available sources.</p>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base"><span className="font-medium">Information from Third Parties:</span> We may receive information about you from third parties, including our business partners, and publicly available sources.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">How We Use Your Personal Information?</h3>
                    <ul className="list-disc ml-5">
                        <li data-aos="fade-up" data-aos-duration="300">
                            <p className="text-base">To provide, maintain, and improve our Services.</p>
                        </li>

                        <li data-aos="fade-up" data-aos-duration="300">
                            <p className="text-base">To process transactions and send related information, including confirmations and invoices.To send you technical notices, updates, security alerts, and support and administrative messages.</p>
                        </li>
                        
                    </ul>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">How We Disclose Your Personal Information?</h3>
                    <ul  className="list-disc ml-5">
                        <li data-aos="fade-up" data-aos-duration="300">
                            <p className="text-base">To service providers, advisors, and other third parties who perform services on our behalf.</p>
                        </li>

                        <li data-aos="fade-up" data-aos-duration="300">
                            <p className="text-base">To comply with the law or to protect the rights, property, or safety of Hume Studios, our users, or others.</p>
                        </li>

                        <li data-aos="fade-up" data-aos-duration="300">
                            <p className="text-base">In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</p>
                        </li>
                        
                    </ul>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Your Privacy Choices and Rights:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">You have certain choices regarding the use of your data, including the rights to access, correct, update, or request deletion of your personal information.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">International Data Transfers:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">You must be at least 18 years old to use the Service. By agreeing to these Terms, you represent and warrant that you are of legal age to form a binding contract with Hume Studios.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Retention of Personal Information:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">We retain personal information we collect from you where we have an ongoing legitimate business need to do so.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Security of Personal Information:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">We implement a variety of security measures designed to maintain the safety of your personal information.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Children’s Information:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">Our Services are not directed to children under the age of 16, and we do not knowingly collect personal information from children under 16.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Third-Party Websites/Applications:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">We are not responsible for the practices employed by websites or services linked to or from our Services, including the information or content contained therein.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Supervisory Authority:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">You have the right to lodge a complaint with your local data protection authority about our collection and use of your personal information.</p>
                </div>

                <div className="data-chunk mt-5">
                    <h3 data-aos="fade-up" data-aos-duration="200"  className=" font-semibold text-base">Contact Us:</h3>
                    <p  data-aos="fade-up" data-aos-duration="300" className="mt-2 text-base">For any questions about these Terms, please contact us at <b> info@humestudios.xyz</b></p>
                </div>
            </div>
            <Footer />
        </div>
    )
}