import { useEffect, useState } from "react";
import Card from "../components/card";
import Footer from "../components/footer";
import Header from "../components/header";
// import vid from '../images/vid.mp4'
import vid from '../images/vide.jpg'
import { ethers } from "ethers";
import { shirtNftAdd } from "../contracts/config.js";
import shirtNftAbi from '../contracts/shirtNftAbi.json'
import { useContractContext } from "../context/nftShirtContext.jsx";
import CartModal from "../modals/cartModal.jsx";
import MintSwiper from "../components/mintSlider.jsx";

export default function MintProductsPage(){
    const {pairOfShirts, setPairofShirts, shirtColors, pricePerShirt} = useContractContext()
    // console.log('Pair of shirts colors =-=>', pricePerShirt);

    useEffect(()=>{
        window.scrollTo(0, 0); 
      });




    return(
        <div className="mint-producpage relative h-screen">
            <Header />
            <div className="whole-sec max-w-[1000px] mx-auto pt-40 px-4">
                <div data-aos="zoom-in-up" data-aos-duration="800" data-aos-easing="ease-out-cubic" className="element-card md:pt-0 relative w-fit mx-auto">
                    <div className="main-element w-fit flex justify-center items-center">
                        <div className=" w-fit relative mx-auto ">
                            {/* <video className="w-full h-full object-cover md:object-cover"  type="video/mp4" autoPlay muted loop>
                                <source src={vid} type="video/mp4" />
                            </video> */}
                            {/* <img className="w-full h-full object-cover md:object-cover" src={vid} alt="tshir image" /> */}
                            {/* <ReactPlayer width={'100%'} style={{'objectFit': 'cover', width:'100%'}} url='https://staging.moviemint.net/uploads/video/admin/video1.mp4' /> */}

                            <MintSwiper />
                        </div>                                
                    </div>
                    <div className="det w-3/4 absolute top-[50%] translate-y-[-50%] left-16 z-50">
                        <p className="text-4xl md:text-6xl text-white font-black uppercase italic">Human Focused Design</p>
                    </div>
                </div>
                <h1 className="pt-8 text-4xl md:pt-20 md:text-5xl font-bold text-center uppercase">Products</h1>
                <div className="cards mt-40 mx-auto grid grid-cols-1 md:grid-cols-1 md:max-w-[448px] gap-y-4 gap-x-4 lg:gap-x-8">

                    <Card pairOfShirts={pairOfShirts} color={'white'} image={'https://assets-global.website-files.com/659ff77944eb912fe4c56ad6/65d3b83be14b96bfd6d859d8_94480ebe-6a20-4bda-9943-9d7acc8b59bf-p-500.jpg'} />
                    {/* <Card color={'black'} image={'https://assets-global.website-files.com/659ff77944eb912fe4c56ad6/65d3b8f1754f39543bdf93d5_d95998ec-c1c5-49e5-9b5e-c8e5e24c68de.jpeg'} /> */}

                </div>
            </div>


            <div className="footer w-full">
                <Footer />
            </div>

        </div>
        
    )
}