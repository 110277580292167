import Footer from '../components/footer'
import Header from '../components/header'
import MissionCard from '../components/missionCard'
import humeImg from '../images/humest1.png'
import { useEffect } from 'react'
import loveSvg from '../images/love.svg'
import likeSvg from '../images/like.svg'
import starSvg from '../images/start.svg'
import webSvg from '../images/web.svg'

export default function MissionPage(){
    useEffect(()=>{
        window.scrollTo(0, 0); 
      });
    return(
        <div className="mission">
            <Header />
            <div className="outer pt-40 max-w-[1480px] mx-auto ">

            
                <div className="hero-sec px-4 lg:px-16 xl:px-0 flex flex-col md:flex-col md:items-center gap-x-4 md:gap-x-8 lg:gap-x-12 xl:gap-x-16">
                    <div className="hero-det md:w-11/12">
                        <h1 data-aos="fade-up" data-aos-duration="700" className='text-3xl lg:text-6xl text-center font-black z-20 text-black'>Hume Studios</h1>
                        <p data-aos="fade-up" data-aos-duration="500" className='paragraphs mt-5 lg:mt-10 text-[#111111] text-center text-base '>We believe Fashion is more than just looking good; it's a powerful form of self-expression that allows you to connect with others and create lasting memories. 
                            It holds immense social, cultural, and artistic significance. 
                            Fashion has the ability challenge norms, defines a period and can be both controversial and revolutionary.
                        </p>
                        <p data-aos="fade-up" data-aos-duration="500" className=' text-base text-[#111111] text-center'>
                            That's why we're using technology to bridge the gap between both worlds and create new ways for people to experience fashion.
                        </p>
                    </div>

                    {/* <div data-aos="fade-up" data-aos-duration="300" className="hero-img mx-auto mt-24 w-[280px]">
                        <img className='' src={'https://assets-global.website-files.com/659ff77944eb912fe4c56ad6/65c907eef9d2dfca1d2eed54_IMG_9561-p-500.jpg'} alt="hume image" />
                    </div> */}
                </div>

                <div className="our-misson max-w-[1040px] mx-auto  px-4 lg:px-16 xl:px-0 mt-12 ">
                    {/* <h1 data-aos="fade-up" data-aos-duration="700" className='text-4xl font-bold  text-center'>Missons</h1> */}
                    <div className="mission-cards mb-10 lg:mb-10 grid gap-x-10 gap-y-10 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                        {/* card */}
                        <MissionCard icon={loveSvg} title={'Community'} det={'We use our networked products to bridge gaps and connect people in unique and innovative ways.'} />
                        <MissionCard icon={likeSvg} title={'Quality'} det={'Each garment is custom-made from the highest quality fabrics available.'} />
                        <MissionCard icon={starSvg} title={'Provenance'} det={'Our products are made to order, and every garment is created on the blockchain, providing provenance and acting as a certificate of authenticity.'} />
                        <MissionCard icon={webSvg} title={'Networked - Products'} det={'Just one simple tap on the Hume Studios logo allows you to connect with and interact with our community.'} />
                        
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}